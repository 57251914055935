import { useEffect, useState } from 'react'
import api from '../../services/api'
import {
  Table,
  Button,
  Form,
  Col,
  FormControl,
  InputGroup,
  Image,
} from 'react-bootstrap'
import prancha from '../../assets/Imagem do sistema.png'
import moment from 'moment'
import { Container, Paper, TablePagination } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import Alert from '@material-ui/lab/Alert'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import Grid from '@material-ui/core/Grid'

import '../Main/Main.css'
import NavBar from '../../components/NavBar/NavBar'
import history from '../../history'
import ToastAnimated, { showToast } from '../../ui-lib/toast'
import 'react-toastify/dist/ReactToastify.css'

function MainDashboard() {
  const [body, setBody] = useState([])
  const [getModelo, setGetModelo] = useState([])
  const [getQuilha, setgetQuilha] = useState([])
  const [getRabeta, setGetRabeta] = useState([])
  const [getAcabamento, setGetAcabamento] = useState([])
  const [getLaminacao, setGetLaminacao] = useState([])

  const [id, setId] = useState(0)
  const [name_user, setName_user] = useState('')
  const [telefone, setTelefone] = useState('')
  const [email, setEmail] = useState('')
  const [modelo, setModelo] = useState('')
  const [tamanho, setTamanho] = useState('')
  const [rabeta, setRabeta] = useState('')
  const [laminacao, setLaminacao] = useState('')
  const [acabamento, setAcabamento] = useState('')
  const [quilha, setQuilha] = useState('')
  const [peso, setPeso] = useState('')
  const [altura, setAltura] = useState('')
  const [calcado, setCalcado] = useState('')
  const [pintura, setPintura] = useState('')
  const [preco, setPreco] = useState('')
  const [entrada, setEntrada] = useState('')
  const [saldo, setSaldo] = useState('')
  const [obs, setObs] = useState('')
  const [dataEntre, setEntre] = useState('')
  const data = new Date()
  const [dataAber, setDataAber] = useState(formatDate(data))
  const [nivel, setNivel] = useState(data.nivel)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const refreshPage = () => {
    window.location.reload()
  }
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  function handleTelefone(event) {
    const regex = /^([0-9]{10 - 15})$/
    var str = event.target.value.replace(/[^0-9]/g, '').slice(0, 15)

    const result = str.replace(regex, '($1)$2-$3')

    setTelefone(result)
  }

  useEffect(() => {
    ;(async () => {
      api.get('/pedidos').then((response) => {
        const respost = response.data
        setBody(respost)
      })
    })()
  }, [])
  async function handleIsertPedido(e) {
    await api
      .post('/pedidos', {
        id: id,
        name_user: name_user,
        telefone: telefone,
        email: email,
        modelo: modelo,
        tamanho: tamanho,
        rabeta: rabeta,
        laminacao: laminacao,
        acabamento: acabamento,
        quilha: quilha,
        peso: peso,
        altura: altura,
        calcado: calcado,
        pintura: pintura,
        preco: preco,
        entrada: entrada,
        saldo: saldo,
        dataAber: dataAber,
        dataEntr: dataEntre,
        observacao: obs,
        nivel: nivel,
        sellerID: localStorage.getItem('user'),
        sellerName: localStorage.getItem('name'),
      })
      .then(() => {
        return refreshPage()
      })
      .catch(() => {
        showToast({ type: 'error', message: 'Erro ao cadastrar pedido' })
      })
  }
  const getData = async () => {
    await api.get('itens/show?item=quilha').then((response) => {
      const result = response.data
      setgetQuilha(result)
    })
    await api.get('itens/show?item=modelo').then((response) => {
      const result = response.data
      setGetModelo(result)
    })
    await api.get('itens/show?item=rabeta').then((response) => {
      const result = response.data
      setGetRabeta(result)
    })
    await api.get('itens/show?item=acabamento').then((response) => {
      const result = response.data
      setGetAcabamento(result)
    })
    await api.get('itens/show?item=laminacao').then((response) => {
      const result = response.data
      setGetLaminacao(result)
    })
  }
  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    setSaldo(preco - entrada)
  })

  return (
    <div>
      <NavBar />
      <ToastAnimated />
      <br />
      <br />
      <div>
        <Container component={Paper} elevation={6}>
          <br />
          <Alert color="info">Cadastrar um novo pedido?</Alert>
          <br />
          <div className="Formulario-pedido">
            <Form>
              <br />
              <Form.Group controlId="exampleForm.ControlInput1">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Container component={Paper} elevation={1}>
                      <Form>
                        <Form.Row className="align-items-center">
                          <Col xs="auto">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                              value={name_user}
                              onChange={(event) => {
                                setName_user(event.target.value)
                              }}
                              type="text"
                              placeholder="Nome completo"
                            />
                          </Col>
                          <Col xs="auto">
                            <Form.Label>Telefone</Form.Label>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text>+</InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                id="inlineFormInputGroupUsername"
                                placeholder="5541999999999"
                                type="tel"
                                pattern="/^[0-9]{10}$/"
                                value={telefone}
                                onChange={handleTelefone.bind(this)}
                              />
                            </InputGroup>
                          </Col>

                          <Col xs="auto">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                              value={email}
                              onChange={(event) => {
                                setEmail(event.target.value)
                              }}
                              type="text"
                              placeholder="name@example.com"
                            />
                          </Col>
                        </Form.Row>
                      </Form>
                      <br />
                    </Container>
                    <br />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Container component={Paper} elevation={1}>
                      <br />
                      <Form.Label>Modelo</Form.Label>
                      <Form.Control
                        as="select"
                        value={modelo}
                        onChange={(event) => {
                          setModelo(event.target.value)
                        }}
                      >
                        <option>Escolha um modelo</option>
                        {getModelo.map((getModelo) => {
                          return (
                            <option key={getModelo.id}>
                              {getModelo.modelo}
                            </option>
                          )
                        })}
                      </Form.Control>
                      <Form.Label>Tamanho</Form.Label>
                      <Form.Control
                        value={tamanho}
                        onChange={(event) => {
                          setTamanho(event.target.value)
                        }}
                      />
                      <Form.Label>Rabeta</Form.Label>
                      <Form.Control
                        as="select"
                        value={rabeta}
                        onChange={(event) => {
                          setRabeta(event.target.value)
                        }}
                      >
                        <option>Escolha uma rabeta</option>
                        {getRabeta.map((getRabeta) => {
                          return (
                            <option key={getRabeta.id}>
                              {getRabeta.rabeta}
                            </option>
                          )
                        })}
                      </Form.Control>
                      <Form.Label>Laminação</Form.Label>
                      <Form.Control
                        as="select"
                        value={laminacao}
                        onChange={(event) => {
                          setLaminacao(event.target.value)
                        }}
                      >
                        <option>Escolha uma laminação</option>
                        {getLaminacao.map((getLaminacao) => {
                          return (
                            <option key={getLaminacao.id}>
                              {getLaminacao.laminacao}
                            </option>
                          )
                        })}
                      </Form.Control>
                      <Form.Label>Acabamento</Form.Label>
                      <Form.Control
                        as="select"
                        value={acabamento}
                        onChange={(event) => {
                          setAcabamento(event.target.value)
                        }}
                      >
                        <option>Escolha um acabamento</option>
                        {getAcabamento.map((getAcabamento) => {
                          return (
                            <option key={getAcabamento.id}>
                              {getAcabamento.acabamento}
                            </option>
                          )
                        })}
                      </Form.Control>
                      <Form.Label>Quilha</Form.Label>
                      <Form.Control
                        as="select"
                        value={quilha}
                        onChange={(event) => {
                          setQuilha(event.target.value)
                        }}
                      >
                        <option>Escolha uma quilha</option>
                        {getQuilha.map((getQuilha) => {
                          return (
                            <option key={getQuilha.id}>
                              {getQuilha.quilha}
                            </option>
                          )
                        })}
                      </Form.Control>
                      <Form>
                        <Form.Row className="align-items-center">
                          <Col>
                            <Form.Label>Peso</Form.Label>
                            <Form.Control
                              value={peso}
                              onChange={(event) => {
                                setPeso(event.target.value)
                              }}
                              type="text"
                              placeholder="Peso"
                            />
                          </Col>
                          <Col>
                            <Form.Label>Altura</Form.Label>
                            <Form.Control
                              value={altura}
                              onChange={(event) => {
                                setAltura(event.target.value)
                              }}
                              type="text"
                              placeholder="Peso"
                            />
                          </Col>
                          <Col>
                            <Form.Label>Calçado</Form.Label>
                            <Form.Control
                              value={calcado}
                              onChange={(event) => {
                                setCalcado(event.target.value)
                              }}
                              type="text"
                            />
                          </Col>
                          <Col>
                            <Form.Label>Nivel</Form.Label>
                            <Form.Control
                              as="select"
                              value={nivel}
                              onChange={(event) => {
                                setNivel(event.target.value)
                              }}
                            >
                              <option>Escolha um nivel</option>
                              <option>Iniciante</option>
                              <option>Intermediario</option>
                              <option>Avancado</option>
                              <option>Competição</option>
                            </Form.Control>
                          </Col>
                        </Form.Row>
                      </Form>
                      <br />
                    </Container>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Container>
                      <Image src={prancha} width={350} height={440} />
                    </Container>
                    <Form.Label>Pintura</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={pintura}
                      onChange={(e) => setPintura(e.target.value)}
                      rows={2}
                    />
                  </Grid>
                </Grid>

                <br />
                <Container component={Paper} elevation={2}>
                  <br />

                  <Form>
                    <Form.Row className="align-items-center">
                      <Col>
                        <Form.Label>Preço </Form.Label>
                        <Form.Control
                          value={preco}
                          type="number"
                          min="1"
                          step="any"
                          onChange={(event) => {
                            setPreco(event.target.value)
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Label>Entrada </Form.Label>
                        <Form.Control
                          type="number"
                          min="1"
                          step="any"
                          value={entrada}
                          onChange={(event) => {
                            setEntrada(event.target.value)
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Label>Saldo </Form.Label>
                        <Form.Control
                          value={saldo}
                          type="number"
                          min="1"
                          step="any"
                          onChange={(event) => {
                            setSaldo(event.target.value)
                          }}
                        />
                      </Col>
                    </Form.Row>
                  </Form>
                  <Form>
                    <Form.Row>
                      <Col>
                        <Form.Label>Data da solicitação</Form.Label>
                        <Form.Control
                          type="date"
                          defaultValue={data}
                          onChange={(event) => {
                            setDataAber(event.target.value)
                          }}
                          value={dataAber}
                        />
                      </Col>
                      <Col>
                        <Form.Label>Data da entrega </Form.Label>
                        <Form.Control
                          type="date"
                          onChange={(event) => {
                            setEntre(event.target.value)
                          }}
                          value={dataEntre}
                        />
                      </Col>
                    </Form.Row>
                  </Form>
                  <Form.Label>Observações</Form.Label>
                  <Form.Control
                    value={obs}
                    as="textarea"
                    rows={5}
                    onChange={(event) => {
                      setObs(event.target.value)
                    }}
                  />
                  <br />
                  <Button variant="outline-dark" onClick={handleIsertPedido}>
                    <BeenhereIcon /> Registrar
                  </Button>

                  <br />

                  <br />
                </Container>
              </Form.Group>
            </Form>
          </div>
          <br />
        </Container>

        <br />
        <br />
        <Container component={Paper} elevation={6}>
          <br />
          <Alert severity="success" color="info">
            Esses são os ultimos 10 pedidos
          </Alert>
          <br />
          <Container component={Paper}>
            <br />
            <br />
            <Table hover responsive="sm" responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Data Entrada</th>
                </tr>
              </thead>
              {body
                .map((body) => {
                  function handleClick() {
                    history.push('/Pedidos')
                    localStorage.setItem('id', body.id)
                    refreshPage()
                  }
                  function handleDelete() {
                    api
                      .post('pedido/delete?id=' + body.id)
                      .then(() => {
                        showToast({
                          type: 'success',
                          message: 'Pedido deletado com sucesso',
                        })
                        refreshPage()
                      })
                      .catch(() => {
                        showToast({
                          type: 'error',
                          message: 'Não foi possivel deletar o pedido',
                        })
                      })
                  }
                  return (
                    <tbody key={body.id}>
                      <tr>
                        <td onClick={handleClick}>{body.id}</td>
                        <td onClick={handleClick}>{body.name_user}</td>
                        <td onClick={handleClick}>
                          {moment(body.dataAber).format('DD/MM/YYYY')}
                        </td>
                        <td>
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={handleDelete}
                          >
                            {' '}
                            <DeleteIcon />
                            Deletar
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  )
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              component="div"
              className="Paginador"
              count={body.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <br />
          </Container>
          <br />
        </Container>
      </div>
    </div>
  )
}

export default MainDashboard
