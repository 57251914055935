import { Router } from 'react-router-dom'
import Routes from './routes'
import history from './history'
import { AuthProvider } from './context/AuthContext'
import { useClearCacheCtx } from 'react-clear-cache'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx()
  return (
    <div>
      {!isLatestVersion && (
        <p>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              emptyCacheStorage()
            }}
          >
            Update version
          </a>
        </p>
      )}
      <AuthProvider className="App">
        <Router history={history}>
          <Routes />
        </Router>
      </AuthProvider>
    </div>
  )
}

export default App
