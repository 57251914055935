import { useState, useEffect } from 'react'
import api from '../../services/api'
import history from '../../history'

import ToastAnimated, { showToast } from '../../ui-lib/toast'
import 'react-toastify/dist/ReactToastify.css'
const refreshPage = () => {
  window.location.reload()
}

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false)
  const [adminauth, setAdminauth] = useState(false)
  const [email, setEmail] = useState('')
  const [token2f, setToken2f] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(true)
  const [borderInput, setBorderInput] = useState('')
  const [borderInputPass, setBorderInputPass] = useState('')
  //Variavel responsavel pelo captcha
  const [cpVeri, setCpVeri] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`
      setAuthenticated(true)
    }
    setLoading(false)
  }, [])

  async function HandleLogin(event) {
    api.defaults.headers.Authorization = undefined
    if (!cpVeri) {
      return showToast({
        type: 'error',
        message: 'Verifique que você não é um robo',
        pauseOnFocusLoss: true,
      })
    } else if (!email) {
      return showToast({
        type: 'error',
        message: 'Preencha seu email',
        pauseOnFocusLoss: true,
      })
    } else if (!password) {
      return showToast({
        type: 'error',
        message: 'Preencha seu senha',
        pauseOnFocusLoss: true,
      })
    } else if (email && password) event.preventDefault()
    try {
      const {
        data: { token, user },
      } = await api.post('/login', {
        email,
        password,
      })

      localStorage.setItem('token', JSON.stringify(token))
      localStorage.setItem('name', user.name)
      localStorage.setItem('email', user.email)
      localStorage.setItem('grupo', user.grupo)
      localStorage.setItem('user', user._id)

      localStorage.setItem('session', 200)

      api.defaults.headers.Authorization = `Bearer ${token}`
      setAuthenticated(true)

      history.push('/Main')
      return refreshPage()
    } catch ({ response }) {
      let errorType
      if (!response) {
        errorType =
          'Servidor nao encontrado ou numero de requisições maximas atingidas, tente novamente em alguns minutos'
        return showToast({
          type: 'error',
          message: `${errorType}`,
          pauseOnFocusLoss: true,
        })
      } else {
        errorType = response.data.erro
        const errorStatus = response.status
        if (errorType === 'Usuario não encontrado') {
          setBorderInput('#ff1a1a')
        } else if (errorType === 'Senha invalida') {
          setBorderInputPass('#ff1a1a')
        } else if (errorStatus === 404) {
          errorType =
            'Servidor nao encontrado. \n Verifique sua conexão com a internet e tente novamente em alguns instantes'
        }
        return showToast({
          type: 'error',
          message: `${errorType}`,
          pauseOnFocusLoss: true,
        })
      }
    }
  }
  function HandleLogout() {
    localStorage.clear()
    setAuthenticated(false)
    api.defaults.headers.Authorization = undefined
    history.push('/login')
  }
  return {
    adminauth,
    authenticated,
    HandleLogin,
    setPassword,
    setEmail,
    email,
    password,
    token2f,
    setToken2f,
    HandleLogout,
    loading,
    borderInputPass,
    borderInput,
    setCpVeri,
  }
}
