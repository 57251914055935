import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache'

ReactDOM.render(
  <ClearCacheProvider duration={5000}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
    ,
  </ClearCacheProvider>,
  document.getElementById('root')
)
