import axios from 'axios'
require('dotenv/config')

let url = `${window.location.host}`
let baseurl = ''

switch (url) {
  case 'localhost:3000':
    baseurl = 'http://localhost:8888/'
    break
  case 'tbs.toor.com.br':
    baseurl = 'https://api.toor.com.br/'
    break

  default:
    baseurl = 'https://api.toor.com.br/'
}

const api = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  baseURL: baseurl,
})

export default api
