import { useEffect, useState } from 'react'
import api from '../../services/api'

import { Container, ListItem, Collapse } from '@material-ui/core'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import './style.css'
export default function ListSeller({ data }) {
  const [open, setOpen] = useState(false)
  const [pedidos, setPedidos] = useState([])
  const [comissao, setComissao] = useState(0)
  const [valor, setValor] = useState(0)
  const pedidosPorVendedor = async () => {
    await api.get(`/pedidos/vendedor${data._id}`).then(({ data, status }) => {
      const { items, comissao, valor } = data
      if (items) {
        setPedidos(items)
        setComissao(comissao)
        setValor(valor)
      }
    })
  }

  useEffect(() => {
    pedidosPorVendedor()
  }, [])
  return (
    <Container maxWidth="xl">
      <ListItem button onClick={() => setOpen(!open)} maxWidth="xl">
        <div className="listItem">
          {open ? (
            <ExpandLess style={{ marginLeft: '-10%' }} />
          ) : (
            <ExpandMore style={{ marginLeft: '-10%' }} />
          )}
          <span> {data.name} </span>
          <p> R$ {valor}</p>
          <p> R$ {comissao}</p>
        </div>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="Items">
          {pedidos[0] &&
            pedidos.map((pedido) => {
              return (
                <ListItem key={pedido.id}>
                  <div className="listItems">
                    <span> {pedido.id} </span>
                    <p> {pedido.name_user}</p>
                    <p> {pedido.preco}</p>
                    <p> {350}</p>
                  </div>
                </ListItem>
              )
            })}
        </div>
      </Collapse>
    </Container>
  )
}
