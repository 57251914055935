import { useEffect, useState } from 'react'
import {
  Col,
  Form,
  Image,
  Button,
  FormControl,
  ButtonToolbar,
  Badge,
} from 'react-bootstrap'

import { Alert, AlertTitle } from '@material-ui/lab'
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import SaveIcon from '@material-ui/icons/Save'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import api from '../../services/api'
import prancha from '../../assets/Imagem do sistema.png'
import ToastAnimated, { showToast } from '../../ui-lib/toast'
import 'react-toastify/dist/ReactToastify.css'
import './styles.css'

const nameSeller = localStorage.getItem('name')
function CorpoPedidoUnic({ data }) {
  const [body, setBody] = useState([])
  const [getModelo, setGetModelo] = useState([])
  const [getQuilha, setgetQuilha] = useState([])
  const [getRabeta, setGetRabeta] = useState([])
  const [getAcabamento, setGetAcabamento] = useState([])
  const [getLaminacao, setGetLaminacao] = useState([])

  const [id, setId] = useState(data.id)
  const [name_user, setName_user] = useState(data.name_user)
  const [telefone, setTelefone] = useState(data.telefone)
  const [email, setEmail] = useState(data.email)
  const [modelo, setModelo] = useState(data.modelo)
  const [tamanho, setTamanho] = useState(data.tamanho)
  const [rabeta, setRabeta] = useState(data.rabeta)
  const [laminacao, setLaminacao] = useState(data.laminacao)
  const [acabamento, setAcabamento] = useState(data.acabamento)
  const [quilha, setQuilha] = useState(data.quilha)
  const [peso, setPeso] = useState(data.peso)
  const [altura, setAltura] = useState(data.altura)
  const [calcado, setCalcado] = useState(data.calcado)
  const [pintura, setPintura] = useState(data.pintura)
  const [preco, setPreco] = useState(data.preco)
  const [entrada, setEntrada] = useState(data.entrada)
  const [saldo, setSaldo] = useState(data.saldo)
  const [obs, setObs] = useState(data.observacao)
  const [dataEntre, setEntre] = useState(data.dataEntr)
  const [dataAber, setDataAber] = useState(data.dataAber)
  const [nivel, setNivel] = useState(data.nivel)

  const [sellers, setSellers] = useState([])

  const refreshPage = () => {
    window.location.reload()
  }

  async function handleUpdatePedido() {
    await api
      .post('/pedido/update', {
        id: id,
        name_user: name_user,
        telefone: telefone,
        email: email,
        modelo: modelo,
        tamanho: tamanho,
        rabeta: rabeta,
        laminacao: laminacao,
        acabamento: acabamento,
        quilha: quilha,
        peso: peso,
        altura: altura,
        calcado: calcado,
        pintura: pintura,
        preco: preco,
        entrada: entrada,
        saldo: saldo,
        dataAber: dataAber,
        dataEntr: dataEntre,
        observacao: obs,
        nivel,
      })
      .then(() => {
        return refreshPage()
      })
      .catch(() => {
        showToast({ type: 'error', message: 'Erro ao cadastrar pedido' })
      })
  }

  const getData = async () => {
    await api.get('itens/show?item=quilha').then((response) => {
      const result = response.data
      setgetQuilha(result)
    })
    await api.get('itens/show?item=modelo').then((response) => {
      const result = response.data
      setGetModelo(result)
    })
    await api.get('itens/show?item=rabeta').then((response) => {
      const result = response.data
      setGetRabeta(result)
    })
    await api.get('itens/show?item=acabamento').then((response) => {
      const result = response.data
      setGetAcabamento(result)
    })
    await api.get('itens/show?item=laminacao').then((response) => {
      const result = response.data
      setGetLaminacao(result)
    })
    await api.get('users').then(({ data }) => {
      setSellers(data)
    })
  }
  useEffect(() => {
    getData()
  }, [])

  async function handleChangeSeller(seller) {
    await api
      .patch(`/pedido/vendedor${data.id}`, JSON.parse(seller))
      .then(() => {
        alert('Vendedor alterado com sucesso')
      })
  }
  function handleTelefone(event) {
    const regex = /^([0-9]{10 - 15})$/
    var str = event.target.value.replace(/[^0-9]/g, '').slice(0, 15)

    const result = str.replace(regex, '($1)$2-$3')

    setTelefone(result)
  }
  useEffect(() => {
    setSaldo(preco - entrada)
  })
  return (
    <div className="Main">
      <ToastAnimated />
      <Container component={Paper} elevation={6} style={{ marginTop: '-30px' }}>
        <br />
        <div className="Header">
          <Image
            src="https://www.tbssurfboards.com.br/wp-content/uploads/2019/11/Logo_black-TBS.png.png"
            width="385px"
          />
          <Typography variant="h1" component="h3">
            <span>{data.id}</span>
            <LocalPrintshopIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.print()
              }}
            />
          </Typography>
        </div>
        <Container elevation={4}>
          <Alert severity="success" color="trasnparent">
            <AlertTitle>
              <span>
                {' '}
                Vendedor:{' '}
                {data.sellerName ? (
                  data.sellerName
                ) : (
                  <Form.Control
                    as="select"
                    defaultValue="Escolha um vendedor"
                    onChange={(e) => handleChangeSeller(e.target.value)}
                  >
                    <option>Escolha um vendedor</option>
                    {sellers.map((seller) => (
                      <option value={JSON.stringify(seller)}>
                        {seller.name}
                      </option>
                    ))}
                  </Form.Control>
                )}
              </span>
            </AlertTitle>
          </Alert>
        </Container>
        <div className="Formulario-pedido">
          <Form>
            <br />
            <Form.Group>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Container component={Paper} elevation={3}>
                    <Form>
                      <Form.Row className="align-items-center">
                        <Col xs="auto">
                          <Form.Label>Nome</Form.Label>
                          <Form.Control
                            value={name_user}
                            onChange={(event) => {
                              setName_user(event.target.value)
                            }}
                            type="text"
                            style={{ fontSize: '25px' }}
                            placeholder="José.."
                          />
                        </Col>
                        <Col xs="auto">
                          <Form.Label>Telefone</Form.Label>
                          <FormControl
                            id="inlineFormInputGroupUsername"
                            placeholder="Telefone"
                            type="tel"
                            style={{ fontSize: '25px' }}
                            pattern="/^[0-9]{10}$/"
                            value={telefone}
                            onChange={handleTelefone.bind(this)}
                          />
                        </Col>
                        <Col xs="auto">
                          <Form.Label>E-mail</Form.Label>
                          <Form.Control
                            value={email}
                            onChange={(event) => {
                              setEmail(event.target.value)
                            }}
                            style={{ fontSize: '25px' }}
                            type="text"
                            placeholder="name@example.com"
                          />
                        </Col>
                      </Form.Row>
                    </Form>
                    <br />
                  </Container>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Container component={Paper} elevation={3} maxWidth="lg">
                    <Form.Label>Modelo</Form.Label>
                    <Form.Control
                      as="select"
                      style={{ fontSize: '25px' }}
                      value={modelo}
                      onChange={(event) => {
                        setModelo(event.target.value)
                      }}
                    >
                      <option>Escolha um modelo</option>
                      {getModelo.map((getModelo) => {
                        return (
                          <option key={getModelo.id}>{getModelo.modelo}</option>
                        )
                      })}
                    </Form.Control>
                    <Form.Label>Tamanho</Form.Label>
                    <Form.Control
                      style={{ fontSize: '25px' }}
                      value={tamanho}
                      onChange={(event) => {
                        setTamanho(event.target.value)
                      }}
                    />
                    <Form.Row className="align-items-center">
                      <Form.Label>Rabeta</Form.Label>
                      <Form.Control
                        style={{ fontSize: '25px' }}
                        as="select"
                        value={rabeta}
                        onChange={(event) => {
                          setRabeta(event.target.value)
                        }}
                      >
                        <option>Escolha uma rabeta</option>
                        {getRabeta.map((getRabeta) => {
                          return (
                            <option key={getRabeta.id}>
                              {getRabeta.rabeta}
                            </option>
                          )
                        })}
                      </Form.Control>

                      <Form.Label>Laminação</Form.Label>
                      <Form.Control
                        style={{ fontSize: '25px' }}
                        as="select"
                        value={laminacao}
                        onChange={(event) => {
                          setLaminacao(event.target.value)
                        }}
                      >
                        <option>Escolha uma laminação</option>
                        {getLaminacao.map((getLaminacao) => {
                          return (
                            <option key={getLaminacao.id}>
                              {getLaminacao.laminacao}
                            </option>
                          )
                        })}
                      </Form.Control>

                      <Form.Label>Acabamento</Form.Label>
                      <Form.Control
                        style={{ fontSize: '25px' }}
                        as="select"
                        value={acabamento}
                        onChange={(event) => {
                          setAcabamento(event.target.value)
                        }}
                      >
                        <option>Escolha um acabamento</option>
                        {getAcabamento.map((getAcabamento) => {
                          return (
                            <option key={getAcabamento.id}>
                              {getAcabamento.acabamento}
                            </option>
                          )
                        })}
                      </Form.Control>

                      <Form.Label>Quilha</Form.Label>
                      <Form.Control
                        style={{ fontSize: '25px' }}
                        as="select"
                        value={quilha}
                        onChange={(event) => {
                          setQuilha(event.target.value)
                        }}
                      >
                        <option>Escolha uma quilha</option>
                        {getQuilha.map((getQuilha) => {
                          return (
                            <option key={getQuilha.id}>
                              {getQuilha.quilha}
                            </option>
                          )
                        })}
                      </Form.Control>
                    </Form.Row>
                    <Form>
                      <Form.Row className="align-items-center">
                        <Col>
                          <Form.Label>Peso</Form.Label>
                          <Form.Control
                            style={{ fontSize: '25px' }}
                            value={peso}
                            onChange={(event) => {
                              setPeso(event.target.value)
                            }}
                            type="text"
                            placeholder="Peso"
                          />
                        </Col>
                        <Col>
                          <Form.Label>Altura</Form.Label>
                          <Form.Control
                            style={{ fontSize: '25px' }}
                            value={altura}
                            onChange={(event) => {
                              setAltura(event.target.value)
                            }}
                            type="text"
                            placeholder="Altura"
                          />
                        </Col>
                        <Col>
                          <Form.Label>Calçado</Form.Label>
                          <Form.Control
                            style={{ fontSize: '25px' }}
                            value={calcado}
                            onChange={(event) => {
                              setCalcado(event.target.value)
                            }}
                            placeholder="Calçado"
                            type="text"
                          />
                        </Col>
                        <Col>
                          <Form.Label>Nível</Form.Label>
                          <Form.Control
                            style={{ fontSize: '25px' }}
                            value={nivel}
                            onChange={(event) => {
                              setNivel(event.target.value)
                            }}
                            placeholder="Nível"
                          />
                        </Col>
                      </Form.Row>
                    </Form>
                    <br />
                  </Container>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Container maxWidth="xl">
                    <Image src={prancha} width={350} height={440} />
                    <br />
                    <Form.Label>Pintura</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={pintura}
                      onChange={(e) => setPintura(e.target.value)}
                      rows={3}
                    />
                  </Container>
                </Grid>
              </Grid>
              <Container
                component={Paper}
                elevation={3}
                style={{ marginTop: '2px' }}
              >
                <Form>
                  <Form.Row className="align-items-center">
                    <Col>
                      <Form.Label>Preço </Form.Label>
                      <Form.Control
                        style={{ fontSize: '25px' }}
                        type="number"
                        value={preco}
                        onChange={(event) => {
                          setPreco(event.target.value)
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Entrada </Form.Label>
                      <Form.Control
                        style={{ fontSize: '25px' }}
                        value={entrada}
                        type="number"
                        onChange={(event) => {
                          setEntrada(event.target.value)
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Saldo </Form.Label>
                      <Form.Control
                        style={{ fontSize: '25px' }}
                        value={saldo}
                        type="number"
                        onChange={(event) => {
                          setSaldo(event.target.value)
                        }}
                      />
                    </Col>
                  </Form.Row>
                </Form>
                <Form>
                  <Form.Row>
                    <Col>
                      <Form.Label>Data da solicitação</Form.Label>
                      <Form.Control
                        style={{ fontSize: '25px' }}
                        defaultValue={data}
                        onChange={(event) => {
                          setDataAber(event.target.value)
                        }}
                        value={dataAber}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Data da entrega </Form.Label>
                      <Form.Control
                        onChange={(event) => {
                          setEntre(event.target.value)
                        }}
                        style={{ fontSize: '25px' }}
                        value={dataEntre}
                      />
                    </Col>
                  </Form.Row>
                </Form>
                <Form.Label>Observações</Form.Label>
                <Form.Control
                  value={obs}
                  as="textarea"
                  rows={2}
                  style={{ fontSize: '25px' }}
                  onChange={(event) => {
                    setObs(event.target.value)
                  }}
                />
                <br />
                <ButtonToolbar className="justify-content-between">
                  <Button
                    style={{ marginBottom: '1%' }}
                    size="sm"
                    aria-label="First group"
                    variant="success"
                    href={`https://wa.me/${data.telefone}?text=Olá ${data.name_user} agora você pode ver seu pedido através deste link! http://tbs.toor.com.br/cliente/pedido?id=${data.pedidoHash}`}
                  >
                    Enviar via WhatsApp {'\n'}
                    <WhatsAppIcon />
                  </Button>

                  <Button
                    style={{ marginBottom: '1%' }}
                    size="sm"
                    variant="outline-dark"
                    aria-describedby="btnGroupAddon2"
                    onClick={handleUpdatePedido}
                  >
                    {'\n '}
                    <SaveIcon fontSize="small" />
                    {'\n '}
                    Salvar Alterações
                  </Button>
                </ButtonToolbar>
              </Container>
            </Form.Group>
          </Form>
        </div>
        <br />
      </Container>
    </div>
  )
}

export default CorpoPedidoUnic
