import { useEffect, useState } from 'react'
import api from '../../services/api'
import { Table, Button, Form } from 'react-bootstrap'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Alert from '@material-ui/lab/Alert'

import '../Main/Main.css'
import NavBar from '../../components/NavBar/NavBar'
import history from '../../history'

import ToastAnimated, { showToast } from '../../ui-lib/toast'
import 'react-toastify/dist/ReactToastify.css'

function MainDashboard() {
  const [body, setBody] = useState([])

  const [modelo, setModelo] = useState('')
  const [quilha, setQuilha] = useState('')
  const [rabeta, setRabeta] = useState('')
  const [acabamento, setAcabamento] = useState('')
  const [laminacao, setLaminacao] = useState('')

  const [getModelo, setGetModelo] = useState([])
  const [getQuilha, setgetQuilha] = useState([])
  const [getRabeta, setGetRabeta] = useState([])
  const [getAcabamento, setGetAcabamento] = useState([])
  const [getLaminacao, setGetLaminacao] = useState([])

  const [custo, setCusto] = useState(0)
  const [idItem, setIdItem] = useState(0)
  const [disableButton, setDisableButton] = useState(false)
  const refreshPage = () => {
    window.location.reload()
  }

  async function handleIsertModelo() {
    await api
      .post('/itens', {
        id: 0,
        modelo: modelo,
        quilha: null,
        rabeta: null,
        acabamento: null,
        custo,
      })
      .then(() => {
        showToast({ type: 'success', message: 'item registrado com sucesso' })
      })
      .catch((response) => {
        showToast({
          type: 'error',
          message:
            'item não registrado, verifique com o administrador do sistema',
        })
      })
  }
  async function handleIsertQuilha() {
    await api
      .post('/itens', {
        id: 0,
        modelo: null,
        quilha: quilha,
        rabeta: null,
        acabamento: null,
        custo,
      })
      .then(() => {
        showToast({ type: 'success', message: 'item registrado com sucesso' })
      })
      .catch((response) => {
        showToast({
          type: 'error',
          message:
            'item não registrado, verifique com o administrador do sistema',
        })
      })
  }
  async function handleIsertRabeta() {
    await api
      .post('/itens', {
        id: 0,
        modelo: null,
        quilha: null,
        rabeta: rabeta,
        acabamento: null,
        custo,
      })
      .then(() => {
        showToast({ type: 'success', message: 'item registrado com sucesso' })
      })
      .catch((response) => {
        showToast({
          type: 'error',
          message:
            'item não registrado, verifique com o administrador do sistema',
        })
      })
  }
  async function handleIsertAcabamento() {
    await api
      .post('/itens', {
        id: 0,
        modelo: null,
        quilha: null,
        rabeta: null,
        acabamento: acabamento,
        custo,
      })
      .then(() => {
        showToast({ type: 'success', message: 'item registrado com sucesso' })
      })
      .catch((response) => {
        showToast({
          type: 'error',
          message:
            'item não registrado, verifique com o administrador do sistema',
        })
      })
  }
  async function handleIsertLaminacao() {
    await api
      .post('/itens', {
        id: 0,
        modelo: null,
        quilha: null,
        rabeta: null,
        acabamento: null,
        laminacao: laminacao,
        custo,
      })
      .then(() => {
        showToast({ type: 'success', message: 'item registrado com sucesso' })
      })
      .catch((response) => {
        showToast({
          type: 'error',
          message:
            'item não registrado, verifique com o administrador do sistema',
        })
      })
  }
  const getData = async () => {
    await api.get('itens/show?item=modelo').then((response) => {
      const result = response.data
      setGetModelo(result)
    })
    await api.get('itens/show?item=quilha').then((response) => {
      const result = response.data
      setgetQuilha(result)
    })

    await api.get('itens/show?item=rabeta').then((response) => {
      const result = response.data
      setGetRabeta(result)
    })
    await api.get('itens/show?item=acabamento').then((response) => {
      const result = response.data
      setGetAcabamento(result)
    })
    await api.get('itens/show?item=laminacao').then((response) => {
      const result = response.data
      setGetLaminacao(result)
    })
    await api.get('/pedidos').then((response) => {
      const respost = response.data
      setBody(respost)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  async function handleUpdateCusto() {
    await api
      .patch(`/items/custo${idItem}`, {
        custo,
      })
      .then(() => {
        alert('custo atualizado com sucesso')
        refreshPage()
      })
      .catch(() => {
        alert('erro ao atualizar custo')
      })
  }
  return (
    <div>
      <NavBar />
      <ToastAnimated />
      <br />
      <br />
      <div>
        <Container component={Paper} elevation={6}>
          <br />
          <Alert severity="error" color="warning">
            Cadastrar um novo item?
          </Alert>
          <br />
          <div className="Formulario-pedido">
            <Form>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Container component={Paper} elevation={1}>
                  <Form.Label>Modelo</Form.Label>
                  <Form.Label>Vizualizar os modelos cadastrados</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      const item = JSON.parse(e.target.value)
                      setModelo(item.modelo)
                      setIdItem(item.id)
                      setDisableButton(true)
                    }}
                  >
                    {getModelo.map((getModelo) => {
                      return (
                        <option value={JSON.stringify(getModelo)}>
                          {getModelo.modelo}
                        </option>
                      )
                    })}
                  </Form.Control>
                  <br />
                  <Form.Control
                    value={modelo}
                    onChange={(event) => {
                      setModelo(event.target.value)
                    }}
                    type="text"
                    placeholder="Modelo"
                  />
                  <br />
                  <Form.Control
                    type="text"
                    placeholder="Custo"
                    onClick={() => setCusto(0)}
                    onChange={(event) => setCusto(event.target.value)}
                  />
                  <br />
                  <Button onClick={handleIsertModelo} disabled={disableButton}>
                    Registrar Modelo
                  </Button>{' '}
                  <Button onClick={handleUpdateCusto} variant="secondary">
                    Alterar
                  </Button>
                  <br />
                  <br />
                  <Form.Label>Quilha</Form.Label>
                  <Form.Label>Vizualizar as quilhas cadastradas</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      const item = JSON.parse(e.target.value)
                      setQuilha(item.quilha)
                      setIdItem(item.id)
                      setDisableButton(true)
                    }}
                  >
                    {getQuilha.map((getQuilha) => {
                      return (
                        <option value={JSON.stringify(getQuilha)}>
                          {getQuilha.quilha}
                        </option>
                      )
                    })}
                  </Form.Control>
                  <br />
                  <Form.Control
                    value={quilha}
                    onChange={(event) => {
                      setQuilha(event.target.value)
                    }}
                    type="text"
                    placeholder="Quilha"
                  />
                  <br />
                  <Form.Control
                    type="text"
                    placeholder="Custo"
                    onClick={() => setCusto(0)}
                    onChange={(event) => setCusto(event.target.value)}
                  />
                  <br />
                  <Button onClick={handleIsertQuilha}>
                    Registrar Quilha
                  </Button>{' '}
                  <Button onClick={handleUpdateCusto} variant="secondary">
                    Alterar
                  </Button>
                  <br />
                  <br />
                  <Form.Label>Rabeta</Form.Label>
                  <Form.Label>Vizualizar as rabetas cadastradas</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      const item = JSON.parse(e.target.value)
                      setRabeta(item.rabeta)
                      setIdItem(item.id)
                      setDisableButton(true)
                    }}
                  >
                    {getRabeta.map((getRabeta) => {
                      return (
                        <option value={JSON.stringify(getRabeta)}>
                          {getRabeta.rabeta}
                        </option>
                      )
                    })}
                  </Form.Control>
                  <br />
                  <Form.Control
                    value={rabeta}
                    onChange={(event) => {
                      setRabeta(event.target.value)
                    }}
                    type="text"
                    placeholder="Rabeta"
                  />
                  <br />
                  <Form.Control
                    type="text"
                    placeholder="Custo"
                    onClick={() => setCusto(0)}
                    onChange={(event) => setCusto(event.target.value)}
                  />
                  <br />
                  <Button onClick={handleIsertRabeta}>
                    Registrar Rabeta
                  </Button>{' '}
                  <Button onClick={handleUpdateCusto} variant="secondary">
                    Alterar
                  </Button>
                  <br />
                  <br />
                  <Form.Label>Vizualizar os acabamentos cadastradas</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      const item = JSON.parse(e.target.value)
                      setAcabamento(item.acabamento)
                      setIdItem(item.id)
                      setDisableButton(true)
                    }}
                  >
                    {getAcabamento.map((getAcabamento) => {
                      return (
                        <option
                          key={getAcabamento.id}
                          value={JSON.stringify(getAcabamento)}
                        >
                          {getAcabamento.acabamento}
                        </option>
                      )
                    })}
                  </Form.Control>
                  <br />
                  <Form.Control
                    value={acabamento}
                    onChange={(event) => {
                      setAcabamento(event.target.value)
                    }}
                    type="text"
                    placeholder="Acabamento"
                  />
                  <br />
                  <Form.Control
                    type="text"
                    placeholder="Custo"
                    onClick={() => setCusto(0)}
                    onChange={(event) => setCusto(event.target.value)}
                  />
                  <br />
                  <Button onClick={handleIsertAcabamento}>
                    Registrar acabamento
                  </Button>{' '}
                  <Button onClick={handleUpdateCusto} variant="secondary">
                    Alterar
                  </Button>
                  <br />
                  <br />
                  <Form.Label>Vizualizar as laminações cadastradas</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      const item = JSON.parse(e.target.value)
                      setLaminacao(item.laminacao)
                      setIdItem(item.id)
                      setDisableButton(true)
                    }}
                  >
                    {getLaminacao.map((getLaminacao) => {
                      return (
                        <option value={JSON.stringify(getLaminacao)}>
                          {getLaminacao.laminacao}
                        </option>
                      )
                    })}
                  </Form.Control>
                  <br />
                  <Form.Control
                    value={laminacao}
                    onChange={(event) => {
                      setLaminacao(event.target.value)
                    }}
                    type="text"
                    placeholder="Laminação"
                  />
                  <br />
                  <Form.Control
                    type="text"
                    placeholder="Custo"
                    onClick={() => setCusto(0)}
                    onChange={(event) => setCusto(event.target.value)}
                  />
                  <br />
                  <Button onClick={handleIsertLaminacao}>
                    Registrar Laminacao
                  </Button>{' '}
                  <Button onClick={handleUpdateCusto} variant="secondary">
                    Alterar
                  </Button>
                  <br />
                  <br />
                </Container>
              </Form.Group>
            </Form>
          </div>
          <br />
        </Container>
        <br />
        <br />
        <Container component={Paper} elevation={6}>
          <br />
          <Alert severity="success" color="info">
            Esses são os ultimos 10 pedidos
          </Alert>
          <br />
          <Table striped hover variant="dark" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Data Entrada</th>
              </tr>
            </thead>
            {body
              .map((body) => {
                function handleClick() {
                  history.push('/Pedidos')
                  localStorage.setItem('id', body.id)
                  refreshPage()
                }
                return (
                  <tbody>
                    <tr>
                      <td onClick={handleClick}>{body.id}</td>
                      <td onClick={handleClick}>{body.name_user}</td>
                      <td onClick={handleClick}>{body.dataAber}</td>
                    </tr>
                  </tbody>
                )
              })
              .slice(0, 10)}
          </Table>
          <br />
        </Container>
      </div>
    </div>
  )
}

export default MainDashboard
