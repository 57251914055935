import { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import loginPage from './pages/Login/Login'
import Main from './pages/Main/Main'
import Pedidos from './pages/Pedidos'
import recoverpass from './pages/Login/passRecovery'
import Chamgepass from './pages/Login/passChange'
import CadastroUser from './pages/CadastroUser'
import CadastroItens from './pages/CadItens'
import TodosPedidos from './pages/TodosPedidos'
import Financeiro from './pages/financeiro'
import ClientePedido from './pages/Pedidos/PublicOrder'

import { Context } from './context/AuthContext'

function CustomRoute({ isPrivate, ...rest }) {
  const { loading, authenticated } = useContext(Context)
  if (loading) {
    return <h1>Loading...</h1>
  }
  if (isPrivate && !authenticated) {
    return <Redirect exact to="/login" />
  }

  return <Route {...rest} />
}

export default function Routes() {
  return (
    <Switch>
      <CustomRoute exact path="/login" component={loginPage} />
      <CustomRoute exact path="/recuperarsenha" component={recoverpass} />
      <CustomRoute exact path="/trocar_senha" component={Chamgepass} />
      <Route exact path="/cliente/pedido" component={ClientePedido} />
      <CustomRoute isPrivate exact path="/" component={Main} />
      <CustomRoute isPrivate exact path="/Main" component={Main} />
      <CustomRoute isPrivate exact path="/Dashboard" component={Main} />
      <CustomRoute isPrivate exact path="/Pedidos" component={Pedidos} />
      <CustomRoute
        isPrivate
        exact
        path="/Cadastrar/usuario"
        component={CadastroUser}
      />
      <CustomRoute
        isPrivate
        exact
        path="/Cadastrar/itens"
        component={CadastroItens}
      />
      <CustomRoute
        isPrivate
        exact
        path="/Pedidos/Todos"
        component={TodosPedidos}
      />
      <CustomRoute isPrivate exact path="/Financeiro" component={Financeiro} />
    </Switch>
  )
}
