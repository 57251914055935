import { useEffect, useState } from 'react'
import api from '../../services/api'
import { Table } from 'react-bootstrap'

import {
  Container,
  Paper,
  List,
  Grid,
  TablePagination,
} from '@material-ui/core'
import moment from 'moment'
import '../Main/Main.css'
import NavBar from '../../components/NavBar/NavBar'
import history from '../../history'
import ListSeller from '../../components/Financeiro/List'
import ToastAnimated, { showToast } from '../../ui-lib/toast'
import 'react-toastify/dist/ReactToastify.css'
import './style.css'
function MainDashboard() {
  moment.locale('pt-br')
  const [body, setBody] = useState([])
  const [pedidos, setPedidos] = useState([])
  const [dataIncial, setDataIncial] = useState('')
  const [dataFinal, setDataFinal] = useState('')
  const [rowsSeller, setRowsSeller] = useState(10)
  const [pageSeller, setPageSeller] = useState(0)

  const handleChangePageSeller = (event, newPage) => {
    setPageSeller(newPage)
  }

  const handleChangeRowsPerPageSeller = (event) => {
    setRowsSeller(parseInt(event.target.value, 10))
    setPageSeller(0)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  async function getData() {
    await api.get('/users').then(({ data }) => {
      setBody(data)
    })
    await api.get('/pedidos').then(({ data }) => {
      setPedidos(data)
    })
  }
  useEffect(() => {
    getData()
  }, [])

  const refreshPage = () => {
    window.location.reload()
  }

  return (
    <div>
      <NavBar />
      <ToastAnimated />
      <br />
      <br />
      <br />
      <div>
        <br />
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <div className="filters">
                <input
                  type="date"
                  onChange={(e) => {
                    setDataIncial(e.target.value)
                  }}
                />
                <input
                  type="date"
                  onChange={(e) => {
                    setDataFinal(e.target.value)
                  }}
                />
              </div>
              <Container component={Paper} maxWidth="xl">
                <List>
                  <div className="listHeader">
                    <label>Detalhes</label>
                    <span>Nome</span>
                    <p>Valor</p>
                    <p>Comissão</p>
                  </div>
                </List>
                {body
                  .map((item) => <ListSeller key={item._id} data={item} />)
                  .slice(
                    pageSeller * rowsSeller,
                    pageSeller * rowsSeller + rowsSeller
                  )}
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={body.length}
                  rowsPerPage={rowsSeller}
                  page={pageSeller}
                  onPageChange={handleChangePageSeller}
                  onRowsPerPageChange={handleChangeRowsPerPageSeller}
                />
              </Container>
            </Grid>

            <br />
            <Grid item xs={12} sm={6}>
              <Container component={Paper}>
                <br />
                <label> Esses são os ultimos pedidos!</label>

                <br />
                <Table hover responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>Data Entrada</th>
                    </tr>
                  </thead>
                  {pedidos.slice(0, 10).map((body) => {
                    function handleClick() {
                      history.push('/pedidos')
                      localStorage.setItem('id', body.id)
                      refreshPage()
                    }
                    return (
                      <tbody onClick={handleClick}>
                        <tr>
                          <td>{body.id}</td>
                          <td>{body.name_user}</td>
                          <td>{moment(body.dataAber).format('DD/MM/YYYY')}</td>
                        </tr>
                      </tbody>
                    )
                  })}
                </Table>

                <br />
              </Container>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  )
}

export default MainDashboard
